import '@hotwired/turbo'
import { encodeMethodIntoRequestBody } from '@hotwired/turbo-rails/app/javascript/turbo/fetch_requests'

import * as TurboInstantClick from './lib/turbo_instant_click'

import RoughNotation from './elements/rough_notation'
import ToggleClass from './elements/toggle_class'
import TawkChat from './elements/tawk_chat'

import Plausible from 'plausible-tracker'

const plausible = Plausible({
  apiHost: 'https://plausible.triplechecker.com',
  domain: 'www.triplechecker.com'
})

if (process.env.NODE_ENV === 'production') {
  plausible.enableAutoPageviews()
}

require.context('./images', true)

TurboInstantClick.start()

document.addEventListener('turbo:before-cache', () => {
  window.flash?.remove()
})

document.addEventListener('keyup', (e) => {
  if (e.code === 'Escape') {
    document.activeElement?.blur()
  }
})

document.addEventListener('turbo:before-fetch-request', encodeMethodIntoRequestBody)

const safeRegisterElement = (name, element, options = {}) => !window.customElements.get(name) && window.customElements.define(name, element, options)

safeRegisterElement('rough-notation', RoughNotation)
safeRegisterElement('toggle-class', ToggleClass)
safeRegisterElement('tawk-chat', TawkChat)
